import REQUEST_FREE_TRIAL from "./mutations/requestFreeTrial.graphql";
import START_FREE_TRIAL from "./mutations/startFreeTrial.graphql";
import GET_TRIAL_BY_ID from "./mutations/getTrialById.graphql";
import TRIAL_EXCHANGE from "./mutations/trialExchange.graphql";

const trialService = ({ gqlClient }) => {
	const requestFreeTrial = (email, turingToken, turingType) =>
		gqlClient(
			REQUEST_FREE_TRIAL,
			{ email },
			{ headers: { "x-turing-token": turingToken, "x-turing-type": turingType } },
		);

	const startFreeTrial = ({
		trialId,
		usageSelect,
		roleSelect,
		countrySelect,
		labName,
		firstName,
		lastName,
		password,
	}) => {
		return gqlClient(START_FREE_TRIAL, {
			trialId,
			extension: {
				groupName: labName,
				usageSelect,
				roleSelect,
				countrySelect,
				...(firstName && { firstName }),
				...(lastName && { lastName }),
				...(password && { password }),
				isIsul: true,
			},
		}).then(data => data.startTrial);
	};

	const getTrialById = id => {
		return gqlClient(GET_TRIAL_BY_ID, {
			trialId: id,
		}).then(data => data.getTrialById);
	};

	const exchangeTrial = requestAccessToken => gqlClient(TRIAL_EXCHANGE, { requestAccessToken });

	return {
		startFreeTrial,
		getTrialById,
		requestFreeTrial,
		exchangeTrial,
	};
};

export default trialService;
