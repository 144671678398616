import { diffDays } from "@insightfulscience/shared-utils/dates/dateDiff";
import { publicConfig } from "../config";
import { isGroupPlan, isIsul as isIsulPlan } from "../domain/plan";

const { nonAutoRenewal, onlinePurchaseAvailable } = publicConfig.PLANS;

const RENEWAL_ENABLED_DAYS = 60;

const PLAN_FLOATING_CRITERIA = "_floating_";
const PLAN_NETWORK_CRITERIA = "_network_";
const PLAN_SERVER_CRITERIA = "_server_";

const SUBSCRIPTION_STATUS = {
	ACTIVE: "active",
	CANCELLED: "cancelled",
	EXPIRED: "expired",
};

const ORDER_STATUS = {
	PENDING: "pending",
	CANCELLED: "cancelled",
};

export const isSubscriptionExpired = ({ isAutoBillingEnabled, expirationDate, status }) => {
	const now = new Date();
	return isAutoBillingEnabled
		? status === SUBSCRIPTION_STATUS.EXPIRED
		: diffDays(now, new Date(expirationDate)) < 0 || status === SUBSCRIPTION_STATUS.EXPIRED;
};

export const isSubscriptionCancelled = subscription =>
	subscription.status === SUBSCRIPTION_STATUS.CANCELLED;
export const isSubscriptionActive = subscription =>
	subscription.status === SUBSCRIPTION_STATUS.ACTIVE;
export const isSubscriptionReturned = subscription =>
	subscription.status === SUBSCRIPTION_STATUS.EXPIRED && subscription.archiveBit;
export const isSubscriptionNonAutoRenewal = subscription =>
	nonAutoRenewal.includes(subscription.plan?.planCode);
export const isSubscriptionOnlinePurchaseAvailable = subscription =>
	onlinePurchaseAvailable.includes(subscription.plan?.planCode);
export const isSubscriptionFloating = subscription =>
	!!subscription.plan?.planCode?.includes(PLAN_FLOATING_CRITERIA);
export const isSubscriptionNetwork = subscription =>
	!!subscription.plan?.planCode?.includes(PLAN_NETWORK_CRITERIA);
export const isSubscriptionServer = subscription =>
	!!subscription.plan?.planCode?.includes(PLAN_SERVER_CRITERIA);
export const isSubscriptionPerpetual = subscription => subscription.expirationDate == null;
export const isAutoBillingSubscription = subscription => subscription.isAutoBillingEnabled;
export const isHasPaymentMethod = subscription => subscription.paymentInfo !== null;

export const hasSusbscriptionDunning = subscription =>
	Array.isArray(subscription.orders) &&
	subscription.orders.some(
		({ status, createdBy }) => status === ORDER_STATUS.PENDING && createdBy === "webhook",
	);

export const isSerialNumberGenerationPending = subscription =>
	subscription?.lastOrder?.isSerialNumberGenerationPending;

export const isAllowedBuyAddons = subscription => subscription?.allowedOperations?.addons;
export const isAllowedViewInvoice = subscription => subscription?.allowedOperations?.viewInvoice;
export const isAllowedRenewal = subscription => subscription?.allowedOperations?.renewal;

export const isAllowRenewExpiredSubscription = ({ plan: { planCode }, expirationDate }) =>
	!isIsulPlan(planCode) ? diffDays(new Date(), new Date(expirationDate)) > -30 : true;

export const isAllowRenewSubscription = ({ plan, isAutoBillingEnabled, expirationDate }) =>
	!isAutoBillingSubscription({ isAutoBillingEnabled }) &&
	diffDays(new Date(), new Date(expirationDate)) <= RENEWAL_ENABLED_DAYS &&
	isAllowRenewExpiredSubscription({ plan, expirationDate });

export const isPurchaseAddonsAllowed = ({ plan: { planCode }, allowedActivations }) =>
	(isIsulPlan(planCode) || allowedActivations > 1) &&
	isSubscriptionOnlinePurchaseAvailable({ plan: { planCode } });

export const getSubscriptionFlags = subscription => {
	const hasDunning = hasSusbscriptionDunning(subscription);
	const isExpired = isSubscriptionExpired(subscription);
	const isCancelled = isSubscriptionCancelled(subscription);
	const isActive = isSubscriptionActive(subscription);
	const isReturned = isSubscriptionReturned(subscription);
	const isNonAutoRenewal = isSubscriptionNonAutoRenewal(subscription);
	const isFloating = isSubscriptionFloating(subscription);
	const isNetwork = isSubscriptionNetwork(subscription);
	const isServer = isSubscriptionServer(subscription);
	const isPerpetual = isSubscriptionPerpetual(subscription);
	const isAutoBillingEnabled = isAutoBillingSubscription(subscription);
	const isRenewalEnabled = isAllowRenewSubscription(subscription);
	const statusEventName =
		(isPerpetual && "PURCHASED") ||
		(isExpired && "EXPIRED") ||
		(isAutoBillingEnabled && isActive && "RENEWS") ||
		"EXPIRES";
	const isSerialNumberGenerationFailed = isSerialNumberGenerationPending(subscription);

	const hasPaymentMethod = isHasPaymentMethod(subscription);

	const isBuyAddonEnabled =
		isAllowedBuyAddons(subscription) && isPurchaseAddonsAllowed(subscription);
	const isViewInvoiceEnabled = isAllowedViewInvoice(subscription);
	const isRenewalButtonEnabled = isAllowedRenewal(subscription);
	const isIsul = isIsulPlan(subscription?.plan?.planCode);
	const licenseCreationFailed = isIsul && !subscription.isulLicenseID;

	return {
		isRenewalEnabled,
		isExpired,
		isCancelled,
		isActive,
		isReturned,
		isNonAutoRenewal,
		isFloating,
		isNetwork,
		isPerpetual,
		hasDunning,
		isAutoBillingEnabled,
		statusEventName,
		isServer,
		isSerialNumberGenerationFailed,
		hasPaymentMethod,
		isBuyAddonEnabled,
		isViewInvoiceEnabled,
		isRenewalButtonEnabled,
		isIsul,
		isGroupPlan: isGroupPlan(subscription?.plan?.type),
		licenseCreationFailed,
		isPurchaseAddonsAllowed: isPurchaseAddonsAllowed(subscription),
	};
};
